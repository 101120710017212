import { useEffect, useMemo, useState } from 'react'
import useAPI from 'services/hooks/useAPI';
import { GetSellerSubsciptions } from './state/sellerEndpoints';
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import { SellerSubscriptionsSelector } from './state/sellerSelectors';
import { sellerSlice } from './state/sellerSilce';
import useAPIWithIncrementsNoPages from 'services/hooks/useAPIWithIncrementsNoPages';
import { useDispatch } from 'react-redux';
import InfinityList from 'tools/infinityList/infinityList';
import { SellerSubscriptionProps } from './state/sellerTypes';
import TemplateRow from 'tools/infinityList/TemplateRow';
import { HorizontalCenter } from 'components/common/Center';
import { Spin } from 'antd';
import FormattedMessage from 'components/common/FormattedMessage';
import Toolbar from 'components/toolbar/Toolbar';
import InfinityListQuery from 'tools/infinityList/infinityListQuery';
import { SellerSubscriptionsColumns, SellerSubscriptionsColumnsToExport, SellerSubscriptionsListHeaders } from './SellerConfig';
import { ExportButton } from 'services/hooks/useDataExport';

export default function SellerSubscriptions() {
  const [query, setQuery] = useState<any>()
  const getSubscriptions = useAPI(GetSellerSubsciptions(), true)

  const subscriptions = useSelectorWithParams([SellerSubscriptionsSelector]);

  const dispatch = useDispatch();
  const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
    callAPI: GetSellerSubsciptions,
    query: query
  })

  useEffect(() => {
    reloadList();
  }, [query]);

  const reloadList = () => {
    dispatch(sellerSlice.actions.clearSubscriptions)
    ListGetSubscriptions();
  }

  const onQueryChange = (query: any) => {
    const normalizedQuery: any = {
      "page[size]": 10,
      "page[number]": 1,
    }

    reloadList();
    setQuery(normalizedQuery);
  }

  const GetMore = () => {
    getNextIncrement((q) => {
      if (!q) {
        return;
      }

      return {
        ...q,
        "page[number]": (q["page[number]"] ?? 1) + 1,
      };
    });
  };

  const handleLoadNextPage = () => {
    if (emptyResponses < 3) GetMore();
  }


  const ListGetSubscriptions = () => {
    if (!query) return;
    startWithNewQuery(query);
  }


  const renderSubscriptions = (subscription: SellerSubscriptionProps) => {
    return (
      <div
        className="infinity-item-wrapper standard-border with-standard-border"
      >
        <TemplateRow size="medium" item={subscription} columns={SellerSubscriptionsColumns} />
      </div>
    )
  }

  const extra = useMemo(() => {

    if (subscriptions && subscriptions.length > 0 && loading) return (
      <HorizontalCenter style={{ padding: "30px" }} key="loading">
        <Spin />
      </HorizontalCenter>
    )
    if (emptyResponses >= 3) return (
      <HorizontalCenter style={{ padding: "30px" }} key="nothing">
        <h3>
          <FormattedMessage
            id="seller.subscriptions.list.error.nothingMore"
            defaultMessage="Nothing more to show. Please change the date range."
          />
        </h3>
      </HorizontalCenter>
    )
    return null
  }, [emptyResponses, loading])

  return (
    <>
      <div className="infinity-wrapper">
        <Toolbar
          width="280px"
          title={(
            <FormattedMessage
              id="panel.components.seller.list.subsciptions"
              defaultMessage="Subsciptions"
            />
          )}
          smallToolbar={(
            <>
              <Toolbar.Spacer />
              <InfinityListQuery
                onChange={onQueryChange}
                onReload={reloadList}
                sort={false}
                date={false}
                refresh={true}
              >
                <ExportButton
                  filename="seller subscriptions"
                  type="csv"
                  columns={SellerSubscriptionsColumnsToExport}
                  items={subscriptions}
                />
              </InfinityListQuery>
            </>
          )}
          largeToolbar={(<>
            <Toolbar.Spacer />
            <InfinityListQuery
              onChange={onQueryChange}
              onReload={reloadList}
              sort={false}
              date={false}
              refresh={true}
            >
              <ExportButton
                filename="seller subscriptions"
                type="csv"
                columns={SellerSubscriptionsColumnsToExport}
                items={subscriptions}
              />
            </InfinityListQuery>
          </>
          )}
          drawerToolbar={(<>
            <InfinityListQuery
              onChange={onQueryChange}
              onReload={reloadList}
              sort={false}
              date={false}
              refresh={true}
            >
            </InfinityListQuery>
          </>
          )}
        />

        <InfinityList
          headers={SellerSubscriptionsListHeaders}
          items={subscriptions}
          loading={getSubscriptions.loading}
          itemSkeleton={() => "skeleton"}
          renderItem={renderSubscriptions}
          loadMoreData={handleLoadNextPage}
          additionalItems={[
            extra
          ]}
        />
      </div>
    </>
  )
}